import React from 'react';

const PageHeader = ({ title }) => {
    return (
        <>
            <div
                className="bg-cover bg-center h-40 flex items-center justify-center sticky top-20 z-40 page-header"
                style={{ backgroundImage: `url('/assets-web/banner-footer.png')`, backgroundPosition: 'center 19%' }} // Ubah nilai ini
            >
                <div className="absolute inset-0 bg-black opacity-70"></div> {/* Overlay */}
                <h1 className="relative text-4xl font-bold text-white z-10">{title}</h1>
            </div>
        </>

    );
};

export default PageHeader;
