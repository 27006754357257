import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function KepemilikanPerusahaan() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of the scroll animation
        });
    }, []);

    return (
        <>
            <div className="">
                <div className="container mx-auto pt-10" id="saham">
                    <div className="text-center mb-8">
                        <h1 className="text-3xl font-bold text-[#005da2]" data-aos="fade-down">
                            Kepemilikan Saham
                        </h1>
                    </div>

                    {/* Shareholders Information */}
                    <div className="flex flex-col lg:flex-row items-center justify-center" data-aos="fade-up">
                        {/* PT Len Industri */}
                        <div className="flex flex-col items-center w-full md:w-1/2 mb-8 md:mb-0">
                            <LazyLoadImage
                                alt="PT Len Industri"
                                src="/assets-web/len_industri_logo.png" // Replace with the actual logo path
                                className="h-40 mb-4 object-contain"
                                effect="blur"
                            />
                            <h2 className="text-xl font-bold">PT Len Industri</h2>
                            <p className="text-lg font-semibold text-red-500">51% Saham</p>
                            <p className="mt-4 text-justify">
                                PT Len Industri (Persero) adalah BUMN Indonesia yang bergerak di bidang teknologi, energi, dan infrastruktur,
                                dengan fokus pada solusi transportasi, pertahanan, dan ICT.
                            </p>
                            <p className='text-left'>Website :  <a
                                href="https://len.co.id/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                len.co.id
                            </a></p>
                        </div>

                        {/* Divider (Line or visual representation) */}
                        <div className="w-full md:w-auto flex justify-center items-center md:mx-8 my-4">
                            <div className="w-32 h-1 bg-red-500 rounded-full"></div>
                        </div>

                        {/* PT TRG Investama */}
                        <div className="flex flex-col items-center w-full md:w-1/2">
                            <LazyLoadImage
                                alt="PT TRG Investama"
                                src="/assets-web/trg_investama_logo.png" // Replace with the actual logo path
                                className="h-40 mb-4 object-contain"
                                effect="blur"
                            />
                            <h2 className="text-xl font-bold">PT TRG Investama</h2>
                            <p className="text-lg font-semibold text-blue-500">49% Saham</p>
                            <p className="mt-4 text-justify">
                                PT TRG Investama adalah perusahaan investasi yang berfokus pada sektor energi, pertambangan, dan infrastruktur di Indonesia.
                            </p>
                            <p>Website : <a
                                href="https://trg-investama.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                trg-investama.com
                            </a>
                            </p>
                        </div>
                    </div>
                    {/* Timeline Section */}
                    <div className="flex flex-col md:flex-row items-center justify-around" data-aos="fade-down">
                        <LazyLoadImage
                            src="/assets-web/photo/dekom-direksi.png"
                            alt="Dekom dan Direksi"
                            className="w-full md:w-4/4 object-cover rounded-lg"
                            effect="blur"
                        />
                    </div>
                </div>
            </div >
        </>
    );
}

export default KepemilikanPerusahaan;
