import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SejarahPerusahaan = () => {
    return (
        <section className="bg-white pt-16">
            <div className="container mx-auto px-4">
                <div className="text-center mb-12" data-aos="fade-down">
                    <h1 className="text-3xl font-bold text-[#005da2]">Sejarah Perusahaan</h1>
                </div>

                {/* Timeline Section */}
                <div className="flex flex-col md:flex-row items-center justify-around" data-aos="fade-down">
                    <LazyLoadImage
                        src="/assets-web/timeline-lti.png"
                        alt="Timeline Sejarah Perusahaan"
                        className="w-full md:w-4/4 object-cover rounded-lg"
                        effect="blur"
                    />
                </div>

                <div className="mt-12 text-center" data-aos="fade-down">
                    <p className="text-lg text-gray-700 leading-relaxed text-justify">
                        Perjalanan PT Len Telekomunikasi Indonesia dimulai sejak 2016, dimulai dengan fase Pre-Planning yang mencakup pendanaan proyek. Selanjutnya, pada tahun yang sama, fase perencanaan proyek dimulai dengan penutupan keuangan. Fase konstruksi dilakukan pada tahun 2018 dan diikuti dengan operasi dan pemeliharaan hingga 2033, untuk memastikan kontribusi jangka panjang bagi telekomunikasi nasional.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default SejarahPerusahaan;
