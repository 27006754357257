import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome CSS
import ReactGA from 'react-ga4';

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize('G-6ZBW5CPQN4'); // Use your GA Measurement ID

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Optionally, track the first page view (homepage)
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
