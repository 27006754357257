import React, { useState, useEffect, useRef } from 'react';

function MapboxMap({ kantorData }) {
    const mapContainerRef = useRef(null);
    const [isMapboxLoaded, setIsMapboxLoaded] = useState(false);

    const loadMapbox = () => {
        const link = document.createElement('link');
        link.href = 'https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        const script = document.createElement('script');
        script.src = 'https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.js';
        script.onload = () => setIsMapboxLoaded(true);
        document.body.appendChild(script);
    };

    useEffect(() => {
        if (!isMapboxLoaded) {
            loadMapbox();
        }

        if (isMapboxLoaded) {
            const mapboxgl = window.mapboxgl;
            mapboxgl.accessToken = 'pk.eyJ1IjoiaGlzeWFtYnNhIiwiYSI6ImNtMHQzNWFpYjBvcGQyanM1Z2tybXZyenkifQ.0-oa8XfRcL1HDegsveqUjQ';

            const map = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/dark-v10',
                center: [106.85508361946768, -6.226420573043901], // Koordinat default
                zoom: 13,
            });

            // Tambahkan marker untuk setiap kantor jika datanya valid
            kantorData.forEach((kantor) => {
                const lat = kantor.attributes.Latitude;
                const lng = kantor.attributes.Longitude;

                if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
                    // Konten popup
                    const popupContent = `
            <div>
              <h3>${kantor.attributes.nama_kantor}</h3>
              <p><strong>Alamat:</strong> ${kantor.attributes.alamat}</p>
              <p><strong>Kota:</strong> ${kantor.attributes.kota}</p>
              <p><strong>Kode Pos:</strong> ${kantor.attributes.kode_pos}</p>
            </div>
          `;

                    // Buat popup
                    const popup = new mapboxgl.Popup({ offset: 25, closeButton: false, anchor: "bottom" })  // Anchor at top
                        .setLngLat([lng, lat])
                        .setHTML(popupContent)
                        .addTo(map);  // Tambahkan popup langsung ke peta

                    // Tambahkan marker dan kaitkan popup dengan marker
                    new mapboxgl.Marker()
                        .setLngLat([lng, lat])
                        .setPopup(popup)  // Tetapkan popup ke marker
                        .addTo(map);

                    // Tampilkan popup otomatis tanpa klik
                    popup.addTo(map);
                } else {
                    console.error("Invalid coordinates for kantor:", kantor);
                }
            });

            return () => map.remove(); // Hapus peta saat komponen di-unmount
        }
    }, [isMapboxLoaded, kantorData]);

    return <div ref={mapContainerRef} style={{ width: '100%', height: '400px' }} />;
}

export default MapboxMap;
