import React from 'react';  // Pastikan useEffect di-import
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function StrukturOrganisasi() {
    return (
        <>
            <section className="bg-white pt-16">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-12" data-aos="fade-down">
                        <h1 className="text-3xl font-bold text-[#005da2]">Struktur Organisasi</h1>
                    </div>

                    {/* Timeline Section */}
                    <div className="flex flex-col md:flex-row items-center justify-around" data-aos="fade-down">
                        <LazyLoadImage
                            src="/assets-web/struktur-organisasi.png"
                            alt="Struktur Organisasi"
                            className="w-full md:w-4/4 object-cover rounded-lg"
                            effect="blur"
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default StrukturOrganisasi;
