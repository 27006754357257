import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { format } from 'date-fns'; // Pastikan date-fns sudah diinstal
import { useParams } from 'react-router-dom';

import PageHeader from '../PageHeader';
function MediaSection() {
    const { jenis } = useParams(); // Ambil parameter 'jenis' dari URL
    const [articles, setArticles] = useState([]); // Inisialisasi dengan array kosong

    useEffect(() => {
        // fetch data hanya jika jenis ada
        fetch(`https://strapis.len-telko.co.id/api/articles?populate=*&filters[jenis][$eq]=${jenis}`)
            .then(response => response.json())
            .then(data => setArticles(data.data))
            .catch(error => console.error('Error fetching articles:', error));

    }, [jenis]);


    return (
        <>
            <PageHeader title="Media" />
            <div className='bg-gray-800 py-16'>
                <div className="container mx-auto py-16">
                    {Array.isArray(articles) && articles.length > 0 ? (
                        articles.map((article, index) => (
                            <div key={index} className="mb-12 flex flex-col md:flex-row items-center p-4 rounded-lg bg-gray-800">
                                {/* Gambar Artikel */}
                                <div className="mr-4 mb-4 md:mb-0" >
                                    <LazyLoadImage
                                        src={`https://strapis.len-telko.co.id${article.attributes.Image?.data[0]?.attributes?.formats?.large?.url || '/default-avatar.jpg'}`} // Fallback jika gambar tidak ada
                                        alt={article.attributes.Author?.data?.attributes?.name || "Image"}
                                        className=""
                                    />
                                </div>

                                {/* Detail Artikel */}
                                <div className="flex flex-col">
                                    {/* Nama Penulis dan Tanggal Post */}
                                    <div className="mb-2">
                                        <p className="text-sm md:text-md text-gray-400">
                                            Posted on {format(new Date(article.attributes.publishedAt), 'MMMM dd, yyyy')}
                                        </p>
                                    </div>

                                    {/* Title sebagai Link ke halaman detail */}
                                    <Link to={`/media/${article.attributes.jenis}/${article.attributes.slug}`} className="text-lg md:text-xl text-gray-200 hover:underline text-justify">
                                        {article.attributes.Title}
                                    </Link>

                                    {/* Konten Artikel (potongan) */}
                                    <p className="text-sm text-gray-400 mt-2 text-justify">
                                        {article.attributes.Description ? article.attributes.Description.slice(0, 350) : "Konten tidak tersedia"}...
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>GAGAL MENGAMBIL DATA</div> // Tampilkan pesan jika tidak ada data
                    )}
                </div>
            </div >
        </>
    );
}

export default MediaSection;
