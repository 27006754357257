import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ProfilPerusahaan = () => {
    return (
        <section className="bg-white pt-10">
            <div className="container mx-auto pb-6">
                {/* Header Section */}
                <div className="mb-12">
                    <h2 className="text-3xl font-bold text-[#005da2] mb-6 text-center" data-aos="fade-down">Profil Perusahaan</h2>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify" data-aos="fade-down">
                        PT Len Telekomunikasi Indonesia (PT Len Telekomunikasi Indonesia) adalah perusahaan yang bergerak dalam pembangunan infrastruktur telekomunikasi nasional, khususnya melalui proyek Palapa Ring Paket Tengah. Sebagai bagian dari Badan Usaha Pelaksana (BUP), PT Len Telekomunikasi Indonesia bertujuan untuk menyediakan konektivitas berkualitas bagi seluruh wilayah Indonesia.
                    </p>
                </div>

                {/* Peran Strategis Section */}
                <div className="mb-12" data-aos="fade-right">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">Peran Strategis</h3>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify">
                        PT Len Telekomunikasi Indonesia memiliki peran strategis dalam menjaga kedaulatan negara serta membangun kesejahteraan masyarakat melalui penyediaan layanan telekomunikasi yang handal dan merata. Dengan proyek-proyek besar seperti Palapa Ring, PT Len Telekomunikasi Indonesia diakui sebagai salah satu pemain kunci dalam dunia telekomunikasi nasional. Proyek ini memungkinkan akses broadband yang lebih luas dan merata, terutama di daerah-daerah 3T (Terdepan, Terluar, dan Tertinggal), yang selama ini kesulitan mendapatkan akses telekomunikasi.
                    </p>
                </div>

                {/* Proyek Utama Section */}
                <div className="mb-12" data-aos="fade-right">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">Proyek Utama</h3>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify">
                        Salah satu proyek utama PT Len Telekomunikasi Indonesia adalah Pembangunan dan Pengelolaan Jaringan Tulang Punggung Serat Optik Palapa Ring Paket Tengah. Proyek ini bertujuan untuk menyediakan infrastruktur yang mendukung percepatan ekonomi berbasis digital di seluruh Indonesia. Infrastruktur yang dibangun terdiri dari jaringan serat optik darat dan laut, yang tersebar di 17 kabupaten atau kota di wilayah Indonesia Bagian Tengah, dengan kabel laut sepanjang 1.825 km dan kabel darat 1.321 km.
                    </p>
                </div>

                {/* Dasar Hukum Section */}
                <div className="mb-12" data-aos="fade-right">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">Dasar Hukum</h3>
                    <p className="text-lg text-gray-700 leading-relaxed text-justify">
                        Dasar hukum yang melandasi aktivitas PT Len Telekomunikasi Indonesia termasuk Perjanjian Kerjasama antara KOMINFO dengan Len Telekomunikasi Indonesia, dengan nomor: 286/M.KOMINFO/HK.03.03/02/2016 dan nomor: 001/PKS/DU/II/2016, tertanggal 4 Maret 2016. Perjanjian ini mengatur tentang pembangunan dan pengelolaan Jaringan Tulang Punggung Serat Optik Palapa Ring Paket Tengah. Dokumen ini menjadi dasar bagi PT Len Telekomunikasi Indonesia untuk melaksanakan seluruh kegiatannya terkait penyediaan layanan konektivitas nasional yang berkualitas.
                    </p>
                </div>

                {/* Skema Section */}
                <div className="flex flex-col md:flex-row items-center justify-around" data-aos="fade-down">
                    <LazyLoadImage
                        src="/assets-web/skema_bisnis.png"
                        alt="Skema Bisnis PT Len Telekomunikasi Indonesia"
                        className="w-full md:w-3/4 object-cover rounded-lg"
                        effect="blur"
                    />
                </div>
            </div>
        </section>
    );
};

export default ProfilPerusahaan;
