import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function LokasiDanWilayah() {
    useEffect(() => {
        AOS.init({
            duration: 3000, // Durasi animasi
        });
    }, []);
    // Contoh data untuk tabel
    const data = [
        { no: 1, name: 'Long Bagun', project: 4, city: 'Mahakam Ulu', province: 'Kalimantan Timur', longitude: '115°14\'29.51"E', latitude: '0°30\'50.87"N', remarks: 'Service Point' },
        { no: 2, name: 'Sendawar', project: 4, city: 'Kab. Kutai Barat', province: 'Kalimantan Timur', longitude: '115°40\'0.44"E', latitude: '0°13\'54.19"S', remarks: 'Interconnection Point' },
        { no: 3, name: 'Bungku', project: 5, city: 'Morowali', province: 'Sulawesi Tengah', longitude: '121°57\'47.45"E', latitude: '2°32\'3.25"S', remarks: 'Service Point' },
        { no: 4, name: 'Petasia', project: 5, city: 'Morowali', province: 'Sulawesi Tengah', longitude: '121°20\'23.78"E', latitude: '1°58\'20.55"S', remarks: 'Service Point' },
        { no: 5, name: 'Tentena', project: 5, city: 'Kab. Poso', province: 'Sulawesi Tengah', longitude: '123°23.685\'E', latitude: '1°13.954\'S', remarks: 'Interconnection Point' },
        { no: 6, name: 'Wanggudu', project: 5, city: 'Konawe Utara', province: 'Sulawesi Tenggara', longitude: '122°6\'57"E', latitude: '3°30\'46"S', remarks: 'Service Point' },
        { no: 7, name: 'Kendari', project: 5, city: 'Kota Kendari', province: 'Sulawesi Tenggara', longitude: '122°30.351"E', latitude: '3°52.689"S', remarks: 'Interconnection Point' },
        { no: 8, name: 'Wawonii', project: 6, city: 'Konawe Kepulauan', province: 'Sulawesi Tenggara', longitude: '122°59\'18"E', latitude: '4°02\'58"S', remarks: 'Service Point' },
        { no: 9, name: 'Raha', project: 6, city: 'Muna', province: 'Sulawesi Tenggara', longitude: '122°42\'58.20"E', latitude: '4°50\'2.44"S', remarks: 'Service Point' },
        { no: 10, name: 'Sawerigadi', project: 6, city: 'Muna Barat', province: 'Sulawesi Tenggara', longitude: '122°25\'59.09"E', latitude: '4°47\'28.73"S', remarks: 'Service Point' },
        { no: 11, name: 'Lakudo', project: 6, city: 'Buton Tengah', province: 'Sulawesi Tenggara', longitude: '122°1\'50.88"E', latitude: '5°17\'38.04"S', remarks: 'Service Point' },
        { no: 12, name: 'Buranga', project: 6, city: 'Buton Utara', province: 'Sulawesi Tenggara', longitude: '122°50\'54.70"E', latitude: '4°45\'12.29"S', remarks: 'Service Point' },
        { no: 13, name: 'Bau-Bau', project: 6, city: 'Kota Bau Bau', province: 'Sulawesi Tenggara', longitude: '122°34\'11.6"E', latitude: '5°31\'32.22"S', remarks: 'Interconnection Point' },
        { no: 14, name: 'Taliabu', project: 7, city: 'Pulau Taliabu', province: 'Maluku Utara', longitude: '124°23\'16.47"E', latitude: '1°57\'00"S', remarks: 'Service Point' },
        { no: 15, name: 'Sanana', project: 7, city: 'Kab. Kepulauan Sula', province: 'Maluku Utara', longitude: '125°58\'53.76"E', latitude: '2°3\'30.60"S', remarks: 'Interconnection Point' },
        { no: 16, name: 'Salakan', project: 7, city: 'Banggai-Kepulauan', province: 'Sulawesi Tengah', longitude: '123°28.635"E', latitude: '1°13.954\'S', remarks: 'Service Point' },
        { no: 17, name: 'Banggai', project: 7, city: 'Banggai', province: 'Sulawesi Tengah', longitude: '123°33\'56"E', latitude: '1°36\'16"S', remarks: 'Service Point' },
        { no: 18, name: 'Luwuk', project: 7, city: 'Kab. Banggai', province: 'Sulawesi Tengah', longitude: '122°47\'35.58"E', latitude: '1°07\'37.68"S', remarks: 'Interconnection Point' },
        { no: 19, name: 'Morotai', project: 8, city: 'Halmahera Utara', province: 'Maluku Utara', longitude: '128°17\'03.02"E', latitude: '2°1\'39.36"N', remarks: 'Service Point' },
        { no: 20, name: 'Tidore', project: 8, city: 'Tidore Kepulauan', province: 'Maluku Utara', longitude: '127°27\'11"E', latitude: '0°41\'37"N', remarks: 'Service Point' },
        { no: 21, name: 'Tobelo', project: 8, city: 'Kab. Halmahera Utara', province: 'Maluku Utara', longitude: '128°0\'2.16"E', latitude: '1°44\'49.84"N', remarks: 'Interconnection Point' },
        { no: 22, name: 'Ternate', project: 8, city: 'Kota Ternate', province: 'Maluku Utara', longitude: '127°23\'11"E', latitude: '0°49\'50"N', remarks: 'Service Point' },
        { no: 23, name: 'Soffi', project: 8, city: 'Kota Soffi', province: 'Maluku Utara', longitude: '127°33\'00"E', latitude: '0°44\'17"N', remarks: 'Interconnection Point' },
        { no: 24, name: 'Ondong Siau', project: 8, city: 'Kep. Siau Tagulandang Biaro', province: 'Sulawesi Utara', longitude: '127°27\'11"E', latitude: '0°41\'37"N', remarks: 'Service Point' },
        { no: 25, name: 'Tahuna', project: 8, city: 'Kepulauan Sangihe', province: 'Sulawesi Utara', longitude: '125°27\'20"E', latitude: '3°36\'59"N', remarks: 'Interconnection Point' },
        { no: 26, name: 'Melonguane', project: 8, city: 'Kepulauan Talaud', province: 'Sulawesi Utara', longitude: '126°41.640"E', latitude: '4°0.118\'N', remarks: 'Service Point' },
        { no: 27, name: 'Manado', project: 8, city: 'Kota Manado', province: 'Sulawesi Utara', longitude: '124°42\'54"E', latitude: '1°25\'16"N', remarks: 'Interconnection Point' }
    ];

    return (
        <>
            <div className="py-16 px-8">
                <div className="text-center mb-8">
                    <h1 className="text-3xl font-bold text-[#005da2]" data-aos="fade-down">
                        Lokasi dan Wilayah
                    </h1>
                </div>

                <div className="container mx-auto">
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-300">
                            <thead className="bg-[#e6212a] text-white">
                                <tr>
                                    <th className="py-3 px-6 text-left font-semibold text-md">No</th>
                                    <th className="py-3 px-6 text-left font-semibold text-md">Nama</th>
                                    <th className="py-3 px-6 text-left font-semibold text-md">Proyek</th>
                                    <th className="py-3 px-6 text-left font-semibold text-md">Kota / Kab</th>
                                    <th className="py-3 px-6 text-left font-semibold text-md">Provinsi</th>
                                    <th className="py-3 px-6 text-left font-semibold text-md">Longitude</th>
                                    <th className="py-3 px-6 text-left font-semibold text-md">Latitude</th>
                                    <th className="py-3 px-6 text-left font-semibold text-md">Catatan</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-700">
                                {data.map((row, index) => (
                                    <tr data-aos="fade-left" key={index} className="border-b border-gray-200 text-xs">
                                        <td className="py-3 px-6">{row.no}</td>
                                        <td className="py-3 px-6">{row.name}</td>
                                        <td className="py-3 px-6">{row.project}</td>
                                        <td className="py-3 px-6">{row.city}</td>
                                        <td className="py-3 px-6">{row.province}</td>
                                        <td className="py-3 px-6">{row.longitude}</td>
                                        <td className="py-3 px-6">{row.latitude}</td>
                                        <td className="py-3 px-6">{row.remarks}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LokasiDanWilayah;
