import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function ProyekStrategisNasional() {
    useEffect(() => {
        AOS.init({
            duration: 2000, // Durasi animasi
        });
    }, []);
    return (
        <>
            <div className="pt-10 px-10">
                <div className="text-center mb-8" data-aos="fade-down">
                    <h1 className="text-3xl font-bold text-[#005da2]">
                        Proyek Strategis Nasional
                    </h1>
                </div>

                <div className="container mx-auto text-justify">
                    <div className="">
                        <p className="text-md leading-7 mb-4" data-aos="fade-right">
                            <strong>Proyek Strategis Nasional (PSN)</strong> adalah inisiatif yang diluncurkan oleh Pemerintah Indonesia untuk mempercepat pembangunan proyek-proyek infrastruktur strategis di berbagai sektor penting di seluruh Indonesia. Program ini bertujuan untuk mendorong pertumbuhan ekonomi nasional dengan memperbaiki infrastruktur di bidang transportasi, energi, perumahan, pengelolaan air, dan infrastruktur digital.
                        </p>
                        <p className="text-md leading-7 mb-4" data-aos="fade-right">
                            Beberapa proyek utama dalam PSN mencakup:
                        </p>
                        <ul className="list-disc list-inside text-md leading-7 mb-4">
                            <li data-aos="fade-right">Pembangunan jalan tol dan sistem transportasi untuk meningkatkan mobilitas dan mengurangi biaya logistik.</li>
                            <li data-aos="fade-right">Pembangkit listrik dan infrastruktur energi untuk menjamin keamanan dan kemandirian energi.</li>
                            <li data-aos="fade-right">Pelabuhan dan bandara guna memperkuat kapasitas perdagangan internasional Indonesia.</li>
                            <li data-aos="fade-right">Infrastruktur telekomunikasi dan digital, seperti <strong>Proyek Palapa Ring</strong>, yang bertujuan untuk menyediakan akses internet hingga ke daerah terpencil dan kurang terlayani.</li>
                        </ul>
                        <p className="text-md leading-7 mb-4" data-aos="fade-right">
                            Melalui PSN, pemerintah berharap dapat mengatasi kesenjangan infrastruktur di berbagai wilayah Indonesia, terutama wilayah yang terpencil dan terpisah oleh geografis yang beragam. Program ini juga berfungsi untuk meningkatkan produktivitas nasional dan memastikan pembangunan yang berkelanjutan, sekaligus mempersiapkan Indonesia agar lebih kompetitif dalam perekonomian global.
                        </p>
                        <p className="text-md leading-7 mb-4" data-aos="fade-right">
                            PSN tidak hanya berfokus pada pembangunan fisik, tetapi juga bertujuan untuk meningkatkan kesejahteraan masyarakat melalui penciptaan lapangan kerja, penurunan tingkat kemiskinan, serta mempercepat konektivitas antara pulau-pulau di seluruh Nusantara.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProyekStrategisNasional;
